import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { formatMoney } from '../../helpers';

const ShoppingCartSummaryItem = ({ title, value, subtitle, money }) => (
  <Grid container direction="row" px={3}>
    <Grid item xs={8} my={1}>
      <Typography variant="shoppingCartSummary">
        {title}
      </Typography>
      <Typography color="primary">
        {subtitle}
      </Typography>
    </Grid>
    <Grid item xs={4} my={1}>
      <Typography variant="shoppingCartSummary">
        {money ? `$${formatMoney(value)}` : value}
      </Typography>
    </Grid>
  </Grid>
);

ShoppingCartSummaryItem.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  subtitle: PropTypes.string.isRequired,
  money: PropTypes.bool.isRequired,
};

export default ShoppingCartSummaryItem;
