import React from 'react';
import PropTypes from 'prop-types';
import { Stack } from '@mui/material';
import { useShoppingCartSummary } from '../../hooks';
import ShoppingCartSummaryItem from './ShoppingCartSummaryItem';

const FactoringOperationSummary = ({ shoppingCartData }) => {
  const formatedShoppingCart = useShoppingCartSummary(shoppingCartData);
  return (
    <Stack px={3} pb={3}>
      {formatedShoppingCart.map(({ title, subtitle, value, money }) => (
        <ShoppingCartSummaryItem
          title={title}
          subtitle={subtitle}
          value={value}
          money={money}
        />
      ))}
    </Stack>
  );
};

FactoringOperationSummary.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  shoppingCartData: PropTypes.objectOf(PropTypes.object).isRequired,
};

export default FactoringOperationSummary;
