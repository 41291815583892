import React from 'react';
import { Typography, Box } from '@mui/material';

const NoRowsGeneric = () => (
  <Box paddingTop={0}>
    <Typography variant="h5" paragraph>
      No tiene documentos disponibles para mostrar
    </Typography>
    <Typography variant="body1" paragraph>
      Intente cambiando los filtros o recargue la página.
    </Typography>
  </Box>
);

export default NoRowsGeneric;
